<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { computed, reactive, watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { useWindowSize, useCssVar, useOnline } from '@vueuse/core'
import EventBus from '@/common/EventBus'

import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

import store from '@/store'
import vertical from './navigation/vertical'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () =>
  import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },

  sockets: {
    connect: function() {
      console.log('connected')
    },
    disconnect: function() {
      console.log('disconnected')
    },
    count_verification() {
      this.socketVerifyRequest()
    },
    count_validation() {
      this.socketValidateRequest()
    },
  },

  // watch online status
  watch: {
    isOnline(online) {
      if (online) {
        this.showBackOnlineToast()
      } else {
        this.showToast()
      }
    },
  },

  beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement,
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement,
        ).value.slice(0, -2),
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },

  mounted() {
    EventBus.on('logout', () => {
      this.logout()
    })
  },

  beforeDestroy() {
    EventBus.remove('logout')
  },

  methods: {
    socketVerifyRequest() {
      this.$store.commit('socket/SOCKET_INCREASE_VERIFICATION_REQUEST')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Verifikasi Pengguna Baru +1',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
      this.$notification.show(
        'Verifikasi Pengguna Baru +1',
        {
          body: '',
        },
        {},
      )
    },
    socketValidateRequest() {
      this.$store.commit('socket/SOCKET_INCREASE_VALIDATION_REQUEST')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Validasi Pengguna Baru +1',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
      this.$notification.show(
        'Validasi Pengguna Baru +1',
        {
          body: '',
        },
        {},
      )
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Remove vuex persistor from localStorage
      localStorage.removeItem('vuex')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    showToast() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'AlertTriangle',
            text: 'You are offline!',
            variant: 'warning',
            hideClose: true,
          },
        },
        { timeout: false, position: 'bottom-center' },
      )
    },
    showBackOnlineToast() {
      this.$toast.clear()
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Welcome back!',
            icon: 'CheckCircle',
            text: 'You are back online!',
            variant: 'success',
          },
        },
        { timeout: 5000, position: 'bottom-center' },
      )
    },
  },

  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout
    const isOnline = useOnline()

    // request notification permission
    Notification.requestPermission().then( permission => {
        if (permission === 'granted') {
            console.log('Notification permission granted.')
        } else {
            console.log('Unable to get permission to notify.')
        }
    })

    // Bug not in user verification
    const userRequest = reactive(
      vertical
        .find(item => item.title === 'Users')
        ?.children.find(item => item.route === 'master-user-verification'),
    )

    const countRequest = computed(() => {
      return store.state.socket.countRequest
    })

    userRequest.tag = countRequest.value

    watch(countRequest, () => {
      userRequest.tag = countRequest.value
    })

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
      isOnline,
      userRequest,
    }
  },
}
</script>

<style>
#nprogress .bar {
  background: var(--primary);
}
</style>
