import BaseJwtService from '@core/auth/jwt/jwtService'

export default class JwtService extends BaseJwtService {
  // refreshToken() {
  //   return this.axiosIns.post(this.jwtConfig.refreshEndpoint)
  // }
}

export function useJwt(axiosIns, jwtOverrideConfig) {
  const jwt = new JwtService(axiosIns, jwtOverrideConfig)

  return {
    jwt,
  }
}
