import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL || 'https://api-dev.nikahsaja.com/api',
  // timeout: 2000,
  // headers: {'X-Custom-Header': 'foobar'}
  withCredentials: true,
})

Vue.prototype.$http = axiosIns

export default axiosIns
