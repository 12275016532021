export default [
  // Help & Support
  {
    path: '/landing/help-support',
    name: 'landing-help-support',
    component: () =>
      import(
        '@/views/landing-pages/help-support/help-support-list/HelpSupportList.vue'
      ),
    meta: {
      resource: 'FaQ',
    },
  },
  {
    path: '/landing/help-support/:slug',
    name: 'landing-help-support-view',
    component: () =>
      import(
        '@/views/landing-pages/help-support/help-support-view/HelpSupportView.vue'
      ),
    meta: {
      resource: 'FaQ',
      navActiveLink: 'landing-help-support',
    },
  },
  {
    path: '/landing/help-support-category',
    name: 'landing-help-support-category',
    component: () =>
      import(
        '@/views/landing-pages/help-support/help-support-category/HelpSupportCategory.vue'
      ),
    meta: {
      resource: 'Category',
    },
  },
  // {
  //   path: '/landing/help-support',
  //   name: 'landing-help-support',
  //   component: () => import('@/views/landing-pages/help-support/help-support-list/HelpSupportList.vue'),
  // },
  // {
  //   path: '/landing/help-support/:slug',
  //   name: 'landing-help-support-view',
  //   component: () => import('@/views/landing-pages/help-support/help-support-view/HelpSupportView.vue'),
  //   meta: {
  //     navActiveLink: 'landing-help-support',
  //   },
  // },
]
