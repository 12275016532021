export default [
  {
    header: 'Landing Page',
    action: 'read',
    resource: 'FaQ',
    // Temporarily set to FaQ. Should refactor if new feature added
  },
  {
    title: 'Help & Support',
    icon: 'HelpCircleIcon',
    children: [
      {
        title: 'FaQ',
        route: 'landing-help-support',
        action: 'read',
        resource: 'FaQ',
      },
      {
        title: 'Category',
        route: 'landing-help-support-category',
        action: 'read',
        resource: 'Category',
      },
    ],
  },
]
