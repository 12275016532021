import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import store from '@/store'

// const options = { path: '/my-app/' } //Options object to pass into SocketIO
const options = { path: '/socket.io/', transports: [ "websocket" ] }

const socketConnection =
  SocketIO(process.env.VUE_APP_SOCKET_URL, options)

Vue.use(
  new VueSocketIO({
    debug: process.env.NODE_ENV !== 'production',
    connection: socketConnection,
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_',
    },
  }),
)
