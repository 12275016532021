import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const dashboard = mock.onGet('/admin/ethnic');
const data = {
  tableBasic: [
    {
      id: "jkhkjh",
      ethnic: "Toraja",
      Status: "Visible",
      
    },
    {
        id: 2,
        ethnic: "Sunda",
        Status: "Hide",
      },
      {
        id: 3,
        ethnic: "Dayak",
        Status: "Visible",
      },
  ],
  /* eslint-disable global-require */
}

mock.onGet('/good-table/ethnic').reply(() => [200, data.tableBasic])




