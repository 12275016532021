export default [
  // JOB
  {
    path: '/master/job',
    name: 'master-job',
    component: () => import('@/views/masters/jobs/jobs-list/JobList.vue'),
    meta: {
      resource: 'Job',
    },
  },

  // ETHNIC
  // {
  //   path: '/master/ethnic',
  //   name: 'master-ethnic',
  //   component: () => import('@/views/masters/ethnic/ethnic-list/EthnicList.vue'),
  //   meta: {
  //     resource: 'Ethnic',
  //   },
  // },

  // BANNER
  // {
  //   path: '/master/banner',
  //   name: 'master-banner',
  //   component: () => import('@/views/masters/banner/banner-list/BannerList.vue'),
  //   meta: {
  //     resource: 'Banner',
  //   },
  // },

  // PROVINCE
  {
    path: '/master/location/province',
    name: 'master-province',
    component: () =>
      import(
        '@/views/masters/location/province/province-list/ProvinceList.vue'
      ),
    meta: {
      resource: 'Province',
    },
  },
  {
    path: '/master/location/country',
    name: 'master-country',
    component: () =>
      import('@/views/masters/location/country/country-list/CountryList.vue'),
    meta: {
      resource: 'Country',
    },
  },

  // City
  {
    path: '/master/location/city',
    name: 'master-city',
    component: () =>
      import('@/views/masters/location/city/city-list/CityList.vue'),
    meta: {
      resource: 'City',
    },
  },
  // Package
  {
    path: '/master/package',
    name: 'master-package',
    component: () =>
      import('@/views/masters/package/package-list/PackageList.vue'),
    meta: {
      resource: 'Package',
    },
  },
  // Vip
  {
    path: '/master/vip',
    name: 'master-vip',
    component: () => import('@/views/masters/vip/vip-list/VipList.vue'),
    meta: {
      resource: 'VIP',
    },
  },

  // USER
  {
    path: '/master/user',
    name: 'master-user',
    component: () => import('@/views/masters/users/user-list/UsersList.vue'),
    meta: {
      resource: 'Users',
    },
  },
  {
    path: '/master/user/view/:id',
    name: 'master-user-view',
    component: () => import('@/views/masters/users/user-view/UserView.vue'),
    meta: {
      resource: 'Users',
      navActiveLink: 'master-user',
    },
  },
  {
    path: '/master/user-verification',
    name: 'master-user-verification',
    component: () =>
      import(
        '@/views/masters/users/user-verification-list/UserVerificationList.vue'
      ),
    meta: {
      resource: 'Users',
    },
  },
  {
    path: '/master/user-validation',
    name: 'master-user-validation',
    component: () =>
      import(
        '@/views/masters/users/user-validation-list/UserValidationList.vue'
      ),
    meta: {
      resource: 'Users',
    },
  },
  {
    path: '/master/user-validation/view/:id',
    name: 'master-user-validation-view',
    component: () =>
      import(
        '@/views/masters/users/user-validation-view/UserValidationView.vue'
      ),
    meta: {
      resource: 'Users',
      navActiveLink: 'master-user-validation',
    },
  },

  // ADMIN MEMBER
  {
    path: '/master/admin-member',
    name: 'master-admin-member',
    component: () =>
      import(
        '@/views/masters/admin-member/admin-member-list/AdminMembersList.vue'
      ),
    meta: {
      resource: 'Admin Members',
    },
  },
  {
    path: '/master/admin-member/view/:id',
    name: 'master-admin-view',
    component: () =>
      import(
        '@/views/masters/admin-member/admin-member-view/AdminMemberView.vue'
      ),
    meta: {
      resource: 'Admin Members',
      navActiveLink: 'master-admin-member',
    },
  },
  {
    path: '/master/admin-member/edit/:id',
    name: 'master-admin-edit',
    component: () =>
      import(
        '@/views/masters/admin-member/admin-member-edit/AdminMemberEdit.vue'
      ),
    meta: {
      resource: 'Admin Members',
      navActiveLink: 'master-admin-member',
    },
  },

  // ROLE
  {
    path: '/master/role',
    name: 'master-role',
    component: () => import('@/views/masters/role/role-list/RoleList.vue'),
    meta: {
      resource: 'Role',
    },
  },

  // REFERRAL CODE
  {
    path: '/master/referral-code',
    name: 'master-referral-code',
    component: () =>
      import(
        '@/views/masters/referral-code/referral-code-list/ReferralCodeList.vue'
      ),
    meta: {
      resource: 'Referral Code',
    },
  },
]
