export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/dashboard/Dashboard.vue'),
    meta: {
      resource: 'Dashboard',
      // resource: 'eCommerce
    },
  },
  // {
  //   path: '/dashboard/analytics',
  //   name: 'dashboard-analytics',
  //   component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  //   meta: {
  //     resource: 'Analytics',
  //   },
  // },
  // {
  //   path: '/dashboard/ecommerce',
  //   name: 'dashboard-ecommerce',
  //   component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  //   meta: {
  //     resource: 'eCommerce',
  //   },
  // },
]
