// ! Jika menambah object baru, mohon sesuaikan dengan urutan abjad.

export default [
  {
    header: 'Master Data',
    action: 'read',
    resource: 'Auth',
  },

  // -------------------------

  {
    title: 'Admin Members',
    route: 'master-admin-member',
    icon: 'KeyIcon',
    action: 'read',
    resource: 'Admin Members',
  },
  // {
  //   title: 'Banner',
  //   route: 'master-banner',
  //   icon: 'ImageIcon',
  //   action: 'read',
  //   resource: 'Banner',
  // },
  // {
  //   title: 'Ethnic',
  //   route: 'master-ethnic',
  //   icon: 'FeatherIcon',
  //   action: 'read',
  //   resource: 'Ethnic',
  // },
  {
    title: 'Job',
    route: 'master-job',
    icon: 'BriefcaseIcon',
    action: 'read',
    resource: 'Job',
  },
  {
    title: 'Location',
    icon: 'MapPinIcon',
    children: [
      // {
      //   title: 'Country',
      //   route: 'master-country',
      //   action: 'read',
      //   resource: 'Country',
      // },
      {
        title: 'Province',
        route: 'master-province',
        action: 'read',
        resource: 'Province',
      },
      {
        title: 'City',
        route: 'master-city',
        action: 'read',
        resource: 'City',
      },
    ],
  },
  {
    title: 'Package',
    route: 'master-package',
    icon: 'FolderIcon',
    action: 'read',
    resource: 'Package',
  },
  {
    title: 'Role',
    route: 'master-role',
    icon: 'StarIcon',
    action: 'read',
    resource: 'Role',
  },
  {
    title: 'Users',
    icon: 'UsersIcon',
    children: [
      {
        title: 'User Data',
        route: 'master-user',
        action: 'read',
        resource: 'Users',
      },
      {
        title: 'User Verification',
        route: 'master-user-verification',
        action: 'read',
        resource: 'User Verification',
      },
      {
        title: 'User Validation',
        route: 'master-user-validation',
        action: 'read',
        resource: 'User Validation',
      },
    ],
  },
  {
    title: "VIP's Price",
    route: 'master-vip',
    icon: 'GiftIcon',
    action: 'read',
    resource: 'VIP',
  },
  {
    title: 'Referral Code',
    route: 'master-referral-code',
    icon: 'CodeIcon',
    action: 'read',
    resource: 'Referral Code',
  },
]
