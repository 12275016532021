export default {
  namespaced: true,
  state: {
    verificationRequest: 0,
    validationRequest: 0,
  },
  getters: {
    getVerificationRequest(state) {
      return state.verificationRequest
    },
    getValidationRequest(state) {
      return state.validationRequest
    },
  },
  mutations: {
    UPDATE_VERIFICATION_REQUEST(state, payload) {
      state.verificationRequest = payload
    },
    UPDATE_VALIDATION_REQUEST(state, payload) {
      state.validationRequest = payload
    },
    SOCKET_INCREASE_VERIFICATION_REQUEST(state) {
      state.verificationRequest++
    },
    SOCKET_INCREASE_VALIDATION_REQUEST(state) {
      state.validationRequest++
    },
    DECREASE_VERIFICATION_REQUEST(state) {
      state.verificationRequest--
    },
    DECREASE_VALIDATION_REQUEST(state) {
      state.validationRequest--
    },
  },
  actions: {},
}
