// import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'
import { useJwt } from './jwtService'

const { jwt } = useJwt(axios, {
  loginEndpoint: 'admin/auth/login',
  refreshEndpoint: 'admin/auth/refresh-token',
  logoutEndpoint: 'admin/auth/logout',
  tokenType: '',
})
export default jwt
